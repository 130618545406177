/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ReactNode } from 'react'
import SelectDropdown from 'react-native-select-dropdown'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { NamedBreakpointStylesExtension } from '../../../services/styles/dependencies/breakpoint-style.type'
import { desktopBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { colors, presets } from '../../../styles/colors'
import { convertFontWeightToFontFamily } from '../../../styles/fonts-map'
import { textSizes } from '../../../styles/text-sizes'
import { Icon_C } from '../Icon/Icon'
//https://www.npmjs.com/package/react-native-select-dropdown

export type Option<T> = {
  label?: string | ReactNode
  value: T
}

type StyleTypes = 'blueCaret' | 'greyChevron'
type SelectStyles = NamedBreakpointStylesExtension<typeof baseStyles>

type Props<T> = {
  options: Option<T>[]
  styleType?: StyleTypes
  initialSelection?: Option<T>['label']
  onSelect: (selectedOption: Option<T>) => void
  styles?: SelectStyles
  buttonTextAfterSelection?(selectedItem: Option<T>): string
}

export function Select_C<T>(props: Props<T>) {
  const styles = useBreakpointStyles({
    styles: baseStyles,
    additionalStyles: [props.styleType && styleTypes[props.styleType], props.styles]
  })
  const {
    dropdown: dropdownStyle,
    head: buttonStyle,
    option: rowStyle,
    headText,
    optionText
  } = styles

  return (
    <SelectDropdown
      data={props.options}
      {...{
        dropdownStyle,
        buttonStyle,
        rowStyle,
        buttonTextStyle: convertFontWeightToFontFamily(headText),
        rowTextStyle: convertFontWeightToFontFamily(optionText)
      }}
      defaultValue={
        props.options.find(
          ({ label, value }) => (isString(label) ?? isString(value)) == props.initialSelection
        ) || props.options[0]
      }
      renderDropdownIcon={() =>
        props.styleType == 'blueCaret' ? (
          <Icon_C name="caret" style={styles.caretIcon} color={presets.primary} colorFill />
        ) : (
          <Icon_C name="chevron" style={styles.chevronIcon} color={colors.gray600} />
        )
      }
      onSelect={(selectedOption) => props.onSelect(selectedOption)}
      buttonTextAfterSelection={(selectedItem) => {
        const option: Option<T> = selectedItem

        return (
          props.buttonTextAfterSelection?.(option) ??
          isString(option.label) ??
          isString(option.value) ??
          ''
        )
      }}
      rowTextForSelection={(item) => {
        const option: Option<T> = item

        return (
          props.buttonTextAfterSelection?.(option) ??
          isString(option.label) ??
          isString(option.value) ??
          ''
        )
      }}
      dropdownOverlayColor={'transparent'}
      selectedRowStyle={{ backgroundColor: colors.gray300 }}
    />
  )

  function isString(x: string | unknown): string | null {
    return typeof x === 'string' ? x : null
  }
}

const baseStyles = createStyles({
  dropdown: {
    base: {
      height: 'auto',
      flex: 1,
      marginBottom: 0,
      minWidth: 150,
      borderRadius: 4,
      overflow: 'hidden',
      maxHeight: 300
    }
  },
  head: {
    base: {
      borderRadius: 4,
      borderWidth: 1,
      backgroundColor: 'white',
      height: 'auto',
      width: 'auto',
      margin: 0
    }
  },
  headText: {
    base: {
      textAlign: 'left',
      ...textSizes.size2,

      fontFamily: 'OpenSans',
      fontWeight: '600SemiBold',
      marginHorizontal: 0,
      margin: 0
    }
  },
  option: {
    base: {
      backgroundColor: '#eee',
      paddingVertical: 8,
      paddingHorizontal: 12
    }
  },
  optionText: {
    base: {
      ...textSizes.size2,

      fontFamily: 'OpenSans',
      fontWeight: '600SemiBold',
      textAlign: 'left',
      marginHorizontal: 0
    }
  },
  chevronIcon: {
    base: { width: 22 }
  },
  caretIcon: {
    base: { height: 5.3 }
  }
})

const blueCaret: SelectStyles = createStyles({
  head: {
    base: {
      borderWidth: 1,
      borderColor: presets.primary,
      borderRadius: 4,
      minWidth: 120,
      height: 30
    }
  },
  headText: {
    base: {
      color: presets.primary
    }
  }
})

const greyChevron: SelectStyles = createStyles({
  head: {
    base: {
      paddingHorizontal: 14,
      height: 31,
      borderWidth: 1,
      borderColor: presets.border,
      gap: 6,
      minWidth: 120
    },
    [desktopBreakpoint]: {
      marginBottom: 0
    }
  },
  headText: {
    base: {
      textAlign: 'left',
      marginHorizontal: 0,
      color: colors.gray700
    }
  }
})

const styleTypes: Record<StyleTypes, SelectStyles> = {
  blueCaret,
  greyChevron
}
