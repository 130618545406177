/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */

import { useEffect, useRef } from 'react'
import { TextInput, View, ViewStyle } from 'react-native'
import { toCapitalCase } from '../../../../helper-functions'
import { SearchAutocomplete } from '../../../../services/data/data-types/fetch-response-types/search-response.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../../styles/colors'
import { Button_C } from '../../../Base/Button/Button'
import { Pressable_C } from '../../../Base/Pressable/Pressable'
import { Text_C } from '../../../Base/Text/Text'
import { Icon_C } from '../../../Base/Icon/Icon'

type SearchBarProps = {
  autoHits: SearchAutocomplete[]
  closeAutocomplete(): void
  inputValue?: string
  onInputChange(inputValue: string): void
  onSubmit(inputValue?: string): void
  searchInput(inputValue: string): void
}

export function SearchBar_C({
  autoHits,
  inputValue,
  closeAutocomplete,
  onSubmit,
  onInputChange,
  searchInput
}: SearchBarProps) {
  const searchRef = useRef<any>(null)

  const styles = useBreakpointStyles({ styles: breakpointStyles })

  useEffect(() => {
    const checkIsOutsideElement = (event: MouseEvent | TouchEvent) => {
      const elem = searchRef.current
      if (elem?.contains(event.target)) return
      closeAutocomplete()
    }

    document.addEventListener('mousedown', checkIsOutsideElement)
    document.addEventListener('touchstart', checkIsOutsideElement)

    return () => {
      document.removeEventListener('mousedown', checkIsOutsideElement)
      document.removeEventListener('touchstart', checkIsOutsideElement)
    }
  }, [searchRef])

  const capitalCase = (str: string) => {
    const strArr = str.split(' ')
    const capitalisedArr = strArr.map((word) => {
      if (word.includes('(')) {
        const bracketArr = word.split('(')
        return `(${bracketArr[1].toUpperCase()}`
      }
      return toCapitalCase(word)
    })

    return capitalisedArr.join(' ')
  }

  return (
    <View style={{ marginTop: 10, zIndex: 1 }}>
      <View style={styles.container}>
        <View style={styles.searchContainer}>
          <View style={styles.inputContainer}>
            <Icon_C name="search" color="#999" style={styles.icon as ViewStyle} />
            <TextInput
              style={styles.input as ViewStyle}
              placeholder="Type in what you are looking for here..."
              onChangeText={(value) => onInputChange(value)}
              value={inputValue}
              placeholderTextColor={colors.gray600}
              onKeyPress={(x) => {
                const isEnter = x.nativeEvent.key == 'Enter'
                if (isEnter) onSubmit(inputValue)
              }}
            />
          </View>

          {autoHits.length > 2 && (
            <View ref={searchRef} style={styles.hitContainer}>
              {autoHits.map((hit, idx) => (
                <Pressable_C
                  key={idx}
                  onPress={() => searchInput(hit.name)}
                  style={{
                    elem: {
                      hovered: {
                        backgroundColor: presets.border
                      }
                    }
                  }}
                >
                  <Text_C numberOfLines={1} ellipsizeMode="head" style={{ padding: 5 }}>
                    {capitalCase(hit.name)}
                  </Text_C>
                </Pressable_C>
              ))}
            </View>
          )}
        </View>

        <Button_C styleType="fillButton" onPress={() => onSubmit(inputValue)}>
          Search
        </Button_C>
      </View>
    </View>
  )
}

const breakpointStyles = createStyles({
  hitContainer: {
    position: 'absolute',
    top: 45,
    right: 0,
    left: 0,
    backgroundColor: 'white',
    borderColor: colors.gray200,
    borderRadius: 5,
    borderWidth: 1,
    zIndex: 1,
    overflow: 'hidden'
  },
  radioContainer: {
    flexDirection: 'row',
    marginTop: 15,
    paddingHorizontal: 5,
    paddingBottom: 15
  },
  radioButtonBorder: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'black',
    borderRadius: 100,
    borderWidth: 1,
    width: 18,
    height: 18
  },
  radioButtonFill: {
    borderColor: 'white',
    borderRadius: 100,
    borderWidth: 1,
    width: 3,
    height: 3,
    padding: 5
  },
  radioButton: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    marginRight: 10
  },
  resultsContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 'auto',
    gap: 10,
    maxWidth: 300
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 15,
    zIndex: 1
  },
  searchContainer: {
    flex: 1
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: presets.border,
    paddingHorizontal: 5,
    borderRadius: 6
  },
  input: {
    flex: 1,
    paddingVertical: 11,
    paddingHorizontal: 5,
    borderWidth: 0,
    outline: 'none'
  },
  icon: {
    marginLeft: 5,
    width: 28,
    height: 28
  }
})
