import { SearchModelType } from '../../../services/data/request-functions/search-request'

export type SearchParams = {
  params: {
    search: string
    type?: SearchModelType
    page?: number
  }
}

type SearchModelOption = {
  name: string
  value?: SearchModelType
}

export const SEARCH_OPTIONS: SearchModelOption[] = [
  {
    name: 'All',
    value: undefined
  },
  {
    name: 'News',
    value: 'article'
  },
  {
    name: 'Track',
    value: 'track'
  },
  {
    name: 'Race',
    value: 'race'
  },
  {
    name: 'Horse',
    value: 'horse'
  },
  {
    name: 'Trainer',
    value: 'trainer'
  },
  {
    name: 'Driver',
    value: 'driver'
  }
]
