/* eslint-disable react/jsx-key */
import { ReactNode, createContext, useEffect, useState } from 'react'

import { DayPhaseLetter } from '../../../services/data/data-types/general-data-types.type'
import { MeetingBasic } from '../../../services/data/data-types/local-data-types/meeting-basic.type'
import {
  MeetingDetailed,
  MeetingDetailedRace
} from '../../../services/data/data-types/local-data-types/meeting-detailed.type'
import { getBlackBookHorses } from '../../../services/data/request-functions/black-book-request'
import {
  MeetingDetailedResponse,
  getMeetingDetailed
} from '../../../services/data/request-functions/meeting-detailed-request'
import {
  MeetingsBasicResponse,
  getMeetingsBasic
} from '../../../services/data/request-functions/meetings-basic-request'
import {
  MeetingPuntersCorner,
  PuntersCornerResponse,
  getPuntersCornerData
} from '../../../services/data/request-functions/punters-corner-request'
import {
  StewardsReportResponse,
  getStewardsReportData
} from '../../../services/data/request-functions/stewards-report-request'
import { ParamList } from '../../../services/routes/screens'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { TabView_C } from '../../Layout/TabView/TabView'
import { MeetingHeader_C } from './components/MeetingHeader'
import { AnalysisTab_C } from './tab-views/AnalysisTab'
import { FieldsAndForm_C } from './tab-views/FieldAndForm/FieldAndForm'
import { GearChangesTab_C } from './tab-views/GearChangesTab'
import { Results_C } from './tab-views/Results/Results'
import { ScratchingsTab_C } from './tab-views/ScratchingsTab'
import { SectionalsTab_C } from './tab-views/Sectionals/SectionalsTab'
import { StewardsReportsTab_C } from './tab-views/StewardsReportsTab'
import { TipsTab_C } from './tab-views/TipsTab'
import { Metadata } from '../../Partials/Metadata/Metadata'

export type MeetingTabName =
  | 'Fields & Form'
  | 'Tips'
  | 'Analysis'
  | 'Gear Changes'
  | 'Sectionals'
  | 'Scratchings'
  | 'Results'
  | 'Stewards Reports'

export const MeetingScreen_C = (): JSX.Element =>
  withScreenWrapper<
    'Meeting',
    [MeetingsBasicResponse, MeetingDetailedResponse, PuntersCornerResponse, StewardsReportResponse]
  >({
    requestsSetup: ({ date, trackSlug, raceNumber, dayPhaseLetter, type }) => [
      getMeetingsBasic({ date, useDesignMock: false }),
      getMeetingDetailed({
        date,
        trackSlug,
        dayPhaseLetter: dayPhaseLetter as DayPhaseLetter,
        raceNumber: raceNumber ?? 'all',
        trial: type == 'trial'
      }),
      getPuntersCornerData({ date }),
      getStewardsReportData({
        date,
        trackSlug,
        dayPhaseLetter: dayPhaseLetter as DayPhaseLetter,
        trial: type == 'trial'
      })
    ],

    view: ({ responses, params, authToken }) => {
      const [
        meetingsResponse,
        detailedMeetingResponse,
        punterCornerResponse,
        stewardsReportResponse
      ] = responses!
      const { meetings: todaysMeetings } = meetingsResponse
      const { meeting: activeMeeting } = detailedMeetingResponse
      const { meetings: puntersCornerMeetings } = punterCornerResponse

      const { stewardsReport } = stewardsReportResponse

      const [blackbookHorseIds, setblackbookHorseIds] = useState<number[]>()

      const selectedRace = activeMeeting.races.find((r) => r.raceNumber)

      const meetingIsInProgress =
        activeMeeting.races.some(raceIsResulted) && !activeMeeting.races.every(raceIsResulted)

      const meetingHorseIds = activeMeeting.races.flatMap((race) =>
        race.runners.flatMap((runner) => runner.horse.id)
      )

      useEffect(() => {
        if (authToken) {
          fetchBlackbookHorseIds()
        }
      }, [meetingHorseIds.length])

      function fetchBlackbookHorseIds() {
        getBlackBookHorses({
          authToken: authToken!,
          horseIds: meetingHorseIds
        }).then(({ horses }) => {
          setblackbookHorseIds(horses.map(({ id }) => id))
        })
      }

      const hasResults = selectedRace
        ? raceIsResulted(selectedRace)
        : activeMeeting.races.some(raceIsResulted)

      const initialActiveTab: MeetingTabName = hasResults ? 'Results' : 'Fields & Form'

      const [activeTab, setActiveTab] = useState<MeetingTabName>(initialActiveTab)

      const context: MeetingContext = {
        params,
        date: params.date,
        selectedRaceNumber: params.raceNumber ? Number(params.raceNumber) : undefined,
        activeMeeting,
        todaysMeetings,
        activeMeetingPuntersCorner: puntersCornerMeetings.find(
          (m) =>
            m.track.name == activeMeeting.track.name && m.dayPhaseLetter == params.dayPhaseLetter
        ),
        activeMeetingBasic: todaysMeetings.find(
          (m) =>
            m.track.name == activeMeeting.track.name &&
            m.dayPhaseLetter == activeMeeting.dayPhaseLetter &&
            m.isTrial == activeMeeting.isTrial
        ),
        activeTab,
        blackbookHorseIds,
        fetchBlackbookHorseIds
      }

      const puntersCornerRaces = context.activeMeetingPuntersCorner?.races

      const hasGearChanges = puntersCornerRaces?.some((r) =>
        r.runners?.some((r) => r.gearAdded || r.gearRemoved)
      )

      const hasScratchings = puntersCornerRaces?.some((r) => r.runners?.some((r) => r.isScratched))

      const tips = context.activeMeetingPuntersCorner?.tips
      const analysis = context.activeMeetingPuntersCorner?.analysis

      const hasSectionalsData = activeMeeting.races
        .filter((race) =>
          !context.selectedRaceNumber ? true : context.selectedRaceNumber == race.raceNumber
        )
        .some((race) => race.runners.some((runner) => runner.sectionalData))

      return (
        <>
          <Metadata
            title={activeMeeting.track.name}
            description={`View all of ${activeMeeting.track.name}'s racing fields and results on harness.org.au`}
          />

          <MeetingContextProvider_C context={context}>
            <TabView_C<MeetingTabName>
              initialActiveTab={activeTab}
              onTabSelected={setActiveTab}
              style={{ rightContainer: { flex: 1 } }}
              tabs={[
                [
                  'Fields & Form',
                  <FieldsAndForm_C />,
                  { lowPriority: hasResults && !meetingIsInProgress }
                ],
                [
                  'Tips',
                  <TipsTab_C />,
                  {
                    hidden: !tips,
                    lowPriority: hasResults && !meetingIsInProgress
                  }
                ],
                [
                  'Analysis',
                  <AnalysisTab_C />,
                  {
                    hidden: !analysis,
                    lowPriority: hasResults && !meetingIsInProgress
                  }
                ],
                [
                  'Gear Changes',
                  <GearChangesTab_C />,
                  {
                    hidden: !hasGearChanges,
                    lowPriority: hasResults && !meetingIsInProgress
                  }
                ],
                [
                  'Scratchings',
                  <ScratchingsTab_C />,
                  {
                    hidden: !hasScratchings,
                    lowPriority: hasResults && !meetingIsInProgress
                  }
                ],
                ['Results', <Results_C />, { hidden: !hasResults }],
                ['Sectionals', <SectionalsTab_C />, { hidden: !hasResults || !hasSectionalsData }],
                [
                  'Stewards Reports',
                  <StewardsReportsTab_C stewardsReport={stewardsReport!} />,
                  {
                    hidden: !stewardsReport
                  }
                ]
              ]}
            >
              <MeetingHeader_C />
            </TabView_C>
          </MeetingContextProvider_C>
        </>
      )

      function raceIsResulted(race: MeetingDetailedRace) {
        return race.status == 'Interim Results' || race.status == 'Final Results'
      }
    }
  })

type MeetingContext = {
  activeTab: MeetingTabName
  params: ParamList['Meeting']
  date: string
  todaysMeetings: MeetingBasic[]
  activeMeeting: MeetingDetailed
  selectedRaceNumber?: number | undefined
  activeMeetingBasic: MeetingBasic | undefined
  activeMeetingPuntersCorner?: MeetingPuntersCorner
  blackbookHorseIds?: number[]
  fetchBlackbookHorseIds: () => void
}

export const MeetingContext = createContext<MeetingContext>({} as MeetingContext)

type MeetingContextProviderProps = {
  context: MeetingContext
  children: ReactNode
}

function MeetingContextProvider_C({ context, children }: MeetingContextProviderProps) {
  return <MeetingContext.Provider value={context}>{children}</MeetingContext.Provider>
}
