import { useFonts } from '@expo-google-fonts/open-sans'
import { useState } from 'react'
import { NavigationContainer } from '@react-navigation/native'

import { LoadingSpinnerOverlay_C } from './components/Base/LoadingSpinnerOverlay/LoadingSpinnerOverlay'
import { DrawerContent_C } from './components/Partials/Drawer/DrawerContent'
import { GlobalProvider } from './global-context'
import { loopObject } from './helper-functions'
import { linkingOptions, MainNavigator, screens } from './services/routes/screens'
import { openSansFonts } from './styles/fonts'

export default function App() {
  const [fontsLoaded] = useFonts(openSansFonts)
  const [, setIsDrawerOpen] = useState(false)
  if (!fontsLoaded) {
    return <></>
  }

  return (
    <GlobalProvider>
      <NavigationContainer linking={linkingOptions}>
        <MainNavigator.Navigator
          initialRouteName="Home"
          backBehavior="history"
          defaultStatus="closed"
          screenOptions={{
            headerShown: false,
            drawerType: 'front',
            drawerPosition: 'right'
          }}
          drawerContent={(props) => (
            <DrawerContent_C
              {...props}
              onDrawerOpen={(isDrawerOpen: boolean) => setIsDrawerOpen(isDrawerOpen)}
            />
          )}
        >
          {loopObject(screens, (name, { component, title, initialParams }) => {
            return (
              <MainNavigator.Screen
                {...{
                  key: name,
                  name,
                  options: { title },
                  component,
                  initialParams
                }}
              />
            )
          })}
        </MainNavigator.Navigator>
        <LoadingSpinnerOverlay_C />
      </NavigationContainer>
    </GlobalProvider>
  )
}
