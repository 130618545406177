import { orderBy, toUpper } from 'lodash'
import { mapSilkObj, removeLeadingSlash, toCapitalCase } from '../../../helper-functions'
import { AustralianState } from '../../../types/map.type'
import { convertAsset } from '../../../utils/assets'
import { fetchData } from '../api-fetch-request'
import { MeetingDetailedFetchResponse } from '../data-types/fetch-response-types/meeting-detailed-response.type'
import {
  DayPhaseLetter,
  meetingStatusMap,
  raceStatusMap,
  startTypeMap
} from '../data-types/general-data-types.type'
import {
  MeetingDetailed,
  MeetingDetailedRace,
  RunnerBasic
} from '../data-types/local-data-types/meeting-detailed.type'

export type MeetingDetailedResponse = {
  meeting: MeetingDetailed
}

type GetMeetingDetailedParams = {
  date: string
  trackSlug: string
  dayPhaseLetter: DayPhaseLetter
  raceNumber: string
  trial?: boolean
}
export async function getMeetingDetailed({
  date,
  trackSlug,
  dayPhaseLetter,
  raceNumber,
  trial
}: GetMeetingDetailedParams): Promise<MeetingDetailedResponse> {
  const endpoint = `racing/${trackSlug}/${date}/${dayPhaseLetter}${raceNumber != 'all' ? `/${raceNumber}` : ''}${trial ? `?trial=true` : ''}`

  return fetchData<MeetingDetailedResponse, MeetingDetailedFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(meetingPayload: MeetingDetailedFetchResponse): MeetingDetailedResponse {
    const {
      track,
      date,
      meeting_status,
      races,
      is_trial,
      track_condition,
      weather,
      day_night_twilight,
      is_tab
    } = meetingPayload
    const {
      circumference,
      desktop_display_name,
      home_straight_length,
      max_front_row_mobile,
      max_row_standing,
      mobile_display_name,
      slug,
      sprint_lane,
      state,
      track_map_image
    } = track

    return {
      meeting: {
        weather: weather ?? undefined,
        trackCondition: track_condition ?? undefined,
        isTrial: is_trial,
        date: new Date(date),
        status: meetingStatusMap[meeting_status],
        dayPhaseLetter: day_night_twilight,
        isTab: is_tab,
        track: {
          name: toCapitalCase(desktop_display_name),
          slug: removeLeadingSlash(slug),
          state: !state ? undefined : (toUpper(state) as AustralianState),
          shortName: mobile_display_name,
          trackMapImage: !track_map_image ? undefined : convertAsset(track_map_image),
          trackDetails: {
            circumference: circumference,
            homeStraightLength: home_straight_length ?? undefined,
            maxFrontRowMobile: max_front_row_mobile,
            maxFrontRowStanding: max_row_standing,
            sprintLane: sprint_lane
          }
        },
        races: orderBy(races, 'race_number').map((race): MeetingDetailedRace => {
          const {
            name,
            short_name,
            race_number,
            total_prize_money,
            scheduled_start_time,
            result_places,
            runners,
            race_class,
            track_record,
            bet_types,
            distance,
            start_type,
            race_status,
            race_notes,
            cumulative_mile,
            gross_time,
            lead_time,
            mile_rate,
            quarters,
            margins,
            finish_photo,
            replay_video,
            stewards_replay_videos,
            has_stewards_replay,
            has_replay
          } = race

          return {
            raceName: name,
            shortName: short_name,
            raceNumber: race_number,
            favorite: undefined, // for punters track favorite runner, no data for this, just placeholder
            trackRecord: !track_record
              ? undefined
              : {
                  distance: track_record.distance,
                  horseName: track_record.horse_name,
                  horseSlug: removeLeadingSlash(track_record.horse_slug),
                  raceNumber: track_record.race_number,
                  startType: track_record.start_type,
                  timeMileRate: track_record.time_mile_rate,
                  raceDate: new Date(track_record.race_date)
                },
            totalPrizeMoney: total_prize_money,
            raceNotes: race_notes ?? undefined,
            startTime: new Date(scheduled_start_time),
            betTypes: bet_types ?? undefined,
            distance,
            resultPlaces: result_places ?? undefined,
            raceClass: race_class ?? undefined,
            startType: startTypeMap[start_type],
            status: raceStatusMap[race_status],
            photoFinish: finish_photo ? convertAsset(finish_photo) : undefined,
            raceReplay: replay_video ? convertAsset(replay_video) : undefined,
            hasReplay: has_replay,
            stewardsReplays: stewards_replay_videos?.map((v) => convertAsset(v, true)),
            hasStewardsReplay: has_stewards_replay,
            runners: orderBy(runners, 'saddlecloth_number').map((runner): RunnerBasic => {
              const {
                horse,
                trainer,
                driver,
                saddlecloth_number,
                other_engagements,
                stats_at_race_time,
                is_scratching,
                is_late_scratching,
                woo_average_odds,
                forecast_odds,
                place,
                margin_metres,
                stewards_comments_short,
                silk,
                last_run,
                prizemoney,
                starting_price,
                claiming_price,
                formatted_handicap,
                barrier,
                stewards_comments_long,
                od_status,
                driver_concession_flags,
                emergency_number,
                past_the_post_place,
                sectionals
              } = runner

              return {
                silk: !silk ? undefined : mapSilkObj(silk),
                runnerNumber: saddlecloth_number,
                form: stats_at_race_time?.last_five_starts_form ?? undefined,
                odds:
                  !woo_average_odds && !forecast_odds
                    ? undefined
                    : woo_average_odds || `${forecast_odds}*`,
                place: place ?? undefined,
                pastThePostPlace: past_the_post_place ?? undefined,
                driverConcession: driver_concession_flags ?? undefined,
                scratched: is_scratching || is_late_scratching,
                isLateScratching: is_late_scratching,
                odStatus: od_status ?? undefined,
                horse: {
                  id: horse.id,
                  name: horse.name ?? 'unnamed',
                  slug: horse.slug ? removeLeadingSlash(horse.slug) : null
                },
                trainer: !trainer
                  ? undefined
                  : {
                      shortName: trainer.mobile_display_name,
                      name: trainer.display_name,
                      slug: trainer.slug ? removeLeadingSlash(trainer.slug) : null
                    },
                // driver should not allow undefined
                driver: !driver
                  ? undefined
                  : {
                      shortName: driver.mobile_display_name,
                      name: driver.display_name,
                      slug: driver.slug ? removeLeadingSlash(driver.slug) : null
                    },
                emergencyNumber: emergency_number ?? undefined,
                otherEng: other_engagements.map((eng) => {
                  return {
                    date: new Date(eng.meeting_date),
                    name: eng.track_name,
                    raceNumber: eng.race_number,
                    trackSlug: removeLeadingSlash(eng.track_slug),
                    dayPhaseLetter: eng.day_night_twilight
                  }
                }),
                sectionalData: !sectionals
                  ? undefined
                  : {
                      last800: sectionals.last_800 ?? undefined,
                      margin400: sectionals.margin_400 ?? undefined,
                      margin800: sectionals.margin_800 ?? undefined,
                      marginFinish: sectionals.finish_margin ?? undefined,
                      q3Split: sectionals.q3_split_800_400 ?? undefined,
                      q4Split: sectionals.q4_split_400_finish ?? undefined,
                      metresGained400Finish: sectionals.meters_gained_400_finish ?? undefined,
                      metresGained800400: sectionals.meters_gained_800_400 ?? undefined,
                      metresGained800Finish: sectionals.meters_gained_800_finish ?? undefined
                    },
                class: stats_at_race_time?.horse_class ?? undefined,
                handicap: formatted_handicap,
                barrier,
                lastRun: !last_run
                  ? undefined
                  : {
                      meetingDate: new Date(last_run.meeting_date),
                      raceNumber: last_run.race_number ?? undefined,

                      dayPhaseLetter: last_run.day_night_twilight,
                      trackName: last_run.desktop_display_name,
                      trackNameShort: last_run.mobile_display_name,
                      trackSlug: removeLeadingSlash(last_run.track_slug),
                      hasReplay: last_run.has_replay,
                      place: last_run.place
                    },
                margin: margin_metres ?? undefined,
                startingPrice: starting_price ?? undefined,
                claimingPrice: claiming_price ?? undefined,
                stewardsCommentShort: stewards_comments_short ?? undefined,
                prizemoney: prizemoney ?? undefined,
                stewardsCommentLong: stewards_comments_long ?? undefined
              }
            }),
            resultsInfo: {
              margins: margins ?? undefined,
              grossTime: gross_time ?? undefined,
              leadTime: lead_time ?? undefined,
              mileRate: mile_rate ?? undefined,
              sectionalTimes: quarters ?? undefined,
              cumulativeMile: cumulative_mile ?? undefined
            }
          }
        })
      }
    }
  }
}
