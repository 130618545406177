import { PropsWithChildren } from 'react'
import { Text, TextProps } from 'react-native'
import { StyleType } from '../../../services/styles/dependencies/breakpoint-style.type'
import { colors } from '../../../styles/colors'
import { TextStyle_T, convertFontWeightToFontFamily } from '../../../styles/fonts-map'
import { textSizes } from '../../../styles/text-sizes'

// two types of text (other components extends and trump)
type StyleTypes = 'textType1' | 'articleTextType'
const defaultTextStyle = 'textType1'

const baseStyle: TextStyle_T = {
  fontFamily: 'OpenSans',
  fontWeight: '500Medium',
  ...textSizes.size3,
  color: colors.gray700
}
export const textBaseStyle = baseStyle

const styleTypes: Record<StyleTypes, StyleType> = {
  textType1: {
    ...textSizes.size3,
    color: colors.gray700
  },
  articleTextType: {
    ...textSizes.size3,
    color: colors.gray700,
    lineHeight: 20,
    marginVertical: 8
  }
}

type Props = Omit<TextProps, 'style'> & {
  style?: TextStyle_T
  type?: StyleTypes // default is type 1
}
/**
 *
 * @param props
 * @returns
 */
export function Text_C(props: PropsWithChildren<Props>) {
  const textTypeStyle = styleTypes[props?.type ?? defaultTextStyle]
  const style = convertFontWeightToFontFamily({
    ...baseStyle,
    ...textTypeStyle,
    ...props.style
  })

  return (
    // second style prop overwrites style in spread props, style must come after it
    <Text testID="text" {...props} style={style}>
      {props.children}
    </Text>
  )
}
