import { addMonths } from 'date-fns'
import { useContext, useState } from 'react'
import { View } from 'react-native'
import { GlobalContext } from '../../../global-context'
import { Pagination } from '../../../services/data/data-types/general-data-types.type'
import { ProgramRace } from '../../../services/data/data-types/local-data-types/program-search'
import { getProgramSearchData } from '../../../services/data/request-functions/program-search-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { tabletBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { textSizes } from '../../../styles/text-sizes'
import { Heading_C } from '../../Base/Heading/Heading'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { PerPageOption, PerPageSelect } from '../../Partials/Pagination/components/PerPageSelect'
import { Pagination_C } from '../../Partials/Pagination/Pagination'
import { ProgramSearchFilters_C } from './components/ProgramSearchFilters'
import { ProgramSearchResults_C } from './components/ProgramSearchResults'
import { ProgramSearchFilter } from './program-search'
import { Metadata } from '../../Partials/Metadata/Metadata'

export function ProgramSearch_C(): JSX.Element {
  return withScreenWrapper<'ProgramSearch'>({
    view: () => {
      const [state, setState] = useState<{
        matchingRaces?: ProgramRace[]
        pagination?: Pagination
        hasDoneSearch?: boolean
      }>({})
      const { selectedState } = useContext(GlobalContext)
      const [filter, setFilter] = useState<ProgramSearchFilter>({
        fromDate: new Date(),
        toDate: addMonths(new Date(), 1),
        state: selectedState ?? 'NSW'
      })

      const styles = useBreakpointStyles({
        styles: breakpointStyles
      })

      function search(filter: ProgramSearchFilter, page?: number, perPage?: PerPageOption) {
        getProgramSearchData({
          filter,
          activePageNumber: page ?? 1,
          perPage
        }).then(({ programSearch: { races: matchingRaces }, pagination }) => {
          setState({ matchingRaces, pagination, hasDoneSearch: true })
        })
      }

      function onPerPageChange(perPage: PerPageOption) {
        search(filter, 1, perPage)
      }

      const hasRaces = !!state?.matchingRaces?.length

      return (
        <>
          <Metadata
            title="Program Search"
            description="Search for upcoming races and past results on harness.org.au"
          />

          <Card_C style={styles.header}>
            <Heading_C styleType="h1">Program Search</Heading_C>

            <ProgramSearchFilters_C {...{ onSearch: search, filter, setFilter }} />
          </Card_C>
          {state?.hasDoneSearch &&
            (!hasRaces ? (
              <View style={styles.noResultsElem}>
                <Text_C style={styles.noResultsText}>No Results</Text_C>
              </View>
            ) : (
              <>
                <PerPageSelect
                  initialSelection={state.pagination?.perPage}
                  onSelect={(perPage) => onPerPageChange(perPage)}
                />
                {state.pagination && (
                  <Pagination_C
                    {...state.pagination}
                    onPageChange={(pageNumber) => search(filter, pageNumber)}
                  />
                )}
                {!!state.matchingRaces?.length && (
                  <ProgramSearchResults_C matchingRaces={state.matchingRaces} />
                )}
                {state.pagination && (
                  <Pagination_C
                    options={{
                      infoSide: 'left'
                    }}
                    {...state.pagination}
                    onPageChange={(pageNumber) => search(filter, pageNumber)}
                  />
                )}
              </>
            ))}
        </>
      )
    }
  })
}

const breakpointStyles = createStyles({
  header: {
    base: {
      gap: 40
    },
    [tabletBreakpoint]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  noResultsElem: { alignSelf: 'center' },
  noResultsText: { ...textSizes.size5, fontStyle: 'italic' }
})
