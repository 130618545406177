import { useContext, useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { GlobalContext } from '../../../../global-context'
import { Horse } from '../../../../services/data/data-types/local-data-types/horse.type'
import { getBlackBookHorses } from '../../../../services/data/request-functions/black-book-request'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../../services/styles/dependencies/style-constants'
import { humanFriendlyDate } from '../../../../utils/date-time'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Link_C } from '../../../Base/Link/Link'
import { Text_C } from '../../../Base/Text/Text'
import { Card_C } from '../../../Layout/Card/Card'
import { BorderGrid_C } from '../../../Layout/Grid/BorderGrid'
import { BlackBookButton_C } from '../../../Partials/BlackBookButton'
import { LinkOrText_C } from '../../../Partials/LinkOrText'

type Props = { horse: Horse }

export function HorseDetails_C({ horse }: Props) {
  const [blackbookHorseIds, setblackbookHorseIds] = useState<number[]>()
  const { authToken } = useContext(GlobalContext)
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { logos, detailsGrid } = getElements()

  const isMobileLayout = breakpointBelow(desktopBreakpoint)

  useEffect(() => {
    fetchBlackbookHorseIds()
  }, [])

  function fetchBlackbookHorseIds() {
    getBlackBookHorses({
      authToken: authToken!,
      horseIds: [horse.id]
    }).then(({ horses }) => {
      setblackbookHorseIds(horses.map(({ id }) => id))
    })
  }

  return (
    <Card_C>
      <View style={styles.topContainer}>
        <View style={{ gap: 10 }}>
          <Heading_C styleType="h1">{horse.name}</Heading_C>
          {logos}
        </View>

        <BlackBookButton_C
          onHorseAdded={fetchBlackbookHorseIds}
          horseId={horse.id}
          horseName={horse.name}
          isAlreadyInBlackbook={Boolean(blackbookHorseIds?.length)}
        />
      </View>
      {detailsGrid}
    </Card_C>
  )

  function getElements() {
    const logos = (
      <View style={styles.flexRow}>
        {horse.futurityEligibility?.map((f, i) => {
          const { title, placeholderImageUrl, viewerSources } = f
          const image = viewerSources?.[0].url
          return (
            <Image
              key={title + i}
              resizeMode="contain"
              source={{ uri: image ?? placeholderImageUrl }}
              style={{ width: 36, aspectRatio: 1.1282 }}
              accessibilityLabel={title}
            />
          )
        })}
      </View>
    )

    const { sire, dam, broodmareSire } = horse

    const detailsGrid = (
      <BorderGrid_C
        gridBreakpointConfig={{
          base: { columns: 2 },
          [desktopBreakpoint]: { columns: 6 }
        }}
        cells={[
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Colour:</Text_C>
                <Text_C>{horse.colour}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Sex:</Text_C>
                <Text_C>{horse.sex}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Foal Date:</Text_C>
                <Text_C>{humanFriendlyDate(horse.birthDate)}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Sire:</Text_C>
                {sire ? (
                  <LinkOrText_C
                    name={sire.name}
                    newTab
                    linkUrl={!sire.slug ? undefined : `horse/${sire.slug}`}
                  />
                ) : (
                  <Text_C>N/A</Text_C>
                )}
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Dam:</Text_C>
                {dam ? (
                  <LinkOrText_C
                    name={dam.name}
                    newTab
                    linkUrl={!dam.slug ? undefined : `horse/${dam.slug}`}
                  />
                ) : (
                  <Text_C>N/A</Text_C>
                )}
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Broodmare Sire:</Text_C>
                {broodmareSire ? (
                  <LinkOrText_C
                    name={broodmareSire.name}
                    newTab
                    linkUrl={!broodmareSire.slug ? undefined : `horse/${broodmareSire.slug}`}
                  />
                ) : (
                  <Text_C>N/A</Text_C>
                )}
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Owners:</Text_C>
                <Text_C>{horse.owners}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Breeder:</Text_C>
                <Text_C>{horse.breeder}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Trainer:</Text_C>
                {!horse.trainer ? (
                  '---'
                ) : !horse.trainer.slug ? (
                  <Text_C>
                    {(isMobileLayout && horse.trainer.shortName) ?? horse.trainer.name}
                  </Text_C>
                ) : (
                  <Link_C navigateTo={['Trainer', { trainerSlug: horse.trainer.slug }]}>
                    {(isMobileLayout && horse.trainer.shortName) ?? horse.trainer.name}
                  </Link_C>
                )}
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Gait:</Text_C>
                <Text_C>{horse.gait}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Class:</Text_C>
                <Text_C>{horse.class}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>HWOE:</Text_C>
                <Text_C>{horse.hwoe}</Text_C>
              </>
            )
          }
        ]}
      ></BorderGrid_C>
    )

    return {
      logos,
      detailsGrid
    }
  }
}

const breakpointStyles = createStyles({
  topContainer: {
    base: {
      alignItems: 'flex-start', // so that elements don't stretch the height
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginBottom: 30
    }
  },
  flexRow: { base: { flexDirection: 'row', gap: 10 } },
  labelText: {
    base: { fontFamily: 'OpenSans', fontWeight: '700Bold' }
  }
})
