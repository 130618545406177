import { useNavigation } from '@react-navigation/native'
import { Image, ImageStyle, Pressable } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'

export function HeaderLogo_C() {
  const navigation = useNavigation()

  const styles = useBreakpointStyles({ styles: breakpointStyles })
  return (
    <Pressable onPress={() => navigation.navigate('Home')}>
      <Image
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        source={require('../../../../assets/images/logo-light.png')}
        style={styles.logo as ImageStyle}
        resizeMode="contain"
      />
    </Pressable>
  )
}

const breakpointStyles = createStyles({
  logo: {
    base: {
      height: 30,
      aspectRatio: 2.848
    },
    large: {
      height: 45
    }
  }
})
