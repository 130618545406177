import { capitalize } from 'lodash'
import { View } from 'react-native'

import { removeLeadingSlash } from '../../../../helper-functions'
import { SearchHit } from '../../../../services/data/data-types/fetch-response-types/search-response.type'
import { colors, presets } from '../../../../styles/colors'
import { humanFriendlyDateTime, humanFriendlyShortDate } from '../../../../utils/date-time'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Link_C } from '../../../Base/Link/Link'
import { Text_C } from '../../../Base/Text/Text'

export function SearchItem_C({ hit }: { hit: SearchHit }): JSX.Element {
  switch (hit.type) {
    case 'driver': {
      const text = (
        <>
          <Text_C style={{ color: presets.primary, fontWeight: '600SemiBold' }}>Driver</Text_C>
          <Heading_C styleType="h3">{hit.driver.name}</Heading_C>
        </>
      )
      return hit.driver.slug ? (
        <Link_C
          navigateTo={['Driver', { driverSlug: removeLeadingSlash(hit.driver.slug) }]}
          style={linkStyles}
        >
          {text}
        </Link_C>
      ) : (
        <View style={noLinkStyle}>{text}</View>
      )
    }
    case 'trainer': {
      const text = (
        <>
          <Text_C style={{ color: presets.primary, fontWeight: '600SemiBold' }}>Trainer</Text_C>
          <Heading_C styleType="h3">{hit.trainer.name}</Heading_C>
        </>
      )
      return hit.trainer.slug ? (
        <Link_C
          navigateTo={['Trainer', { trainerSlug: removeLeadingSlash(hit.trainer.slug) }]}
          style={linkStyles}
        >
          {text}
        </Link_C>
      ) : (
        <View style={noLinkStyle}>{text}</View>
      )
    }

    case 'horse': {
      const text = (
        <>
          <Text_C style={{ color: presets.primary, fontWeight: '600SemiBold' }}>Horse</Text_C>
          <Heading_C styleType="h3">{hit.horse.name}</Heading_C>
          <Text_C style={{ fontSize: 14 }}>
            {capitalize(hit.horse.colour)} {capitalize(hit.horse.gender)}{' '}
            {humanFriendlyShortDate(hit.horse.foalingDate)}
          </Text_C>
        </>
      )

      return hit.horse.slug ? (
        <Link_C
          navigateTo={['Horse', { horseSlug: removeLeadingSlash(hit.horse.slug) }]}
          style={linkStyles}
        >
          {text}
        </Link_C>
      ) : (
        <View style={noLinkStyle}>{text}</View>
      )
    }
    case 'article': {
      const text = (
        <>
          <Text_C style={{ color: presets.primary, fontWeight: '600SemiBold' }}>News</Text_C>
          <Heading_C styleType="h3">{hit.article.headline}</Heading_C>
          <Text_C>{hit.article.content}</Text_C>
        </>
      )
      return hit.article.slug ? (
        <Link_C
          navigateTo={[
            'NewsArticle',
            {
              newsId: `${hit.article.id}`,
              newsSlug: removeLeadingSlash(hit.article.slug)
            }
          ]}
          style={linkStyles}
        >
          {text}
        </Link_C>
      ) : (
        <View style={noLinkStyle}>{text}</View>
      )
    }
    case 'page': {
      const text = (
        <>
          <Text_C style={{ color: presets.primary, fontWeight: '600SemiBold' }}>Pages</Text_C>
          <Heading_C styleType="h3">{hit.page.title}</Heading_C>
          <Text_C>{hit.page.content}</Text_C>
        </>
      )
      return hit.page.slug ? (
        <Link_C
          navigateTo={[
            'Pages',
            {
              pageSlug: removeLeadingSlash(hit.page.slug)
            }
          ]}
          style={linkStyles}
        >
          {text}
        </Link_C>
      ) : (
        <View style={noLinkStyle}>{text}</View>
      )
    }
    case 'race': {
      const hasLink = !!hit.race.slug
      const linkUrl = hasLink ? `/racing/${hit.race.slug}` : ''

      const text = (
        <>
          <Text_C style={{ color: presets.primary, fontWeight: '600SemiBold' }}>Race</Text_C>

          <View style={{ flexDirection: 'row', columnGap: 5 }}>
            <Heading_C styleType="h3" style={{ color: hasLink ? colors.black : colors.gray500 }}>
              {hit.race.name}
            </Heading_C>
          </View>

          <View style={{ flexDirection: 'row', columnGap: 5 }}>
            {!!hit.race.raceNumber && (
              <Text_C
                style={{
                  justifyContent: 'center',
                  fontSize: 14,
                  color: hasLink ? colors.black : colors.gray500
                }}
              >
                R{hit.race.raceNumber}
              </Text_C>
            )}

            {!!hit.race.distance && (
              <Text_C
                style={{
                  justifyContent: 'center',
                  fontSize: 14,
                  color: hasLink ? colors.black : colors.gray500
                }}
              >
                {hit.race.distance}m
              </Text_C>
            )}

            {!!hit.race.startTime && (
              <Text_C
                style={{
                  justifyContent: 'center',
                  fontSize: 14,
                  color: hasLink ? colors.black : colors.gray500
                }}
              >
                {humanFriendlyDateTime(hit.race.startTime)}
              </Text_C>
            )}
          </View>
        </>
      )
      return hit.race.slug ? (
        <Link_C linkUrl={linkUrl} style={linkStyles}>
          {text}
        </Link_C>
      ) : (
        <View style={noLinkStyle}>{text}</View>
      )
    }
    case 'track': {
      const text = (
        <>
          <Text_C style={{ color: presets.primary, fontWeight: '600SemiBold' }}>Track</Text_C>
          <Heading_C styleType="h3">{hit.track.name}</Heading_C>
          <Text_C>{hit.track?.about}</Text_C>
        </>
      )
      return hit.track.slug ? (
        <Link_C
          navigateTo={['Track', { trackSlug: removeLeadingSlash(hit.track.slug) }]}
          style={linkStyles}
        >
          {text}
        </Link_C>
      ) : (
        <View style={noLinkStyle}>{text}</View>
      )
    }
    default:
      return <></>
  }
}

const itemStyle = {
  gap: 5,
  paddingVertical: 10,
  paddingHorizontal: 10
}

const noLinkStyle = {
  ...itemStyle,
  background: colors.gray50
}

const linkStyles = {
  elem: {
    base: itemStyle,
    hovered: {
      backgroundColor: presets.background
    }
  }
}
